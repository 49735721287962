import React from 'react';

const handleClick = (handler?: () => void) => (event: React.MouseEvent) => {
  if (!handler)
    return;

  event.preventDefault();
  handler();
};

const LinkBlank: React.FC<{ className?: string, href?: string, onClick?: () => void }> = (props) => {
  return (
    <a 
      className={props.className}
      href={props.href}
      onClick={handleClick(props.onClick)}
      target="_blank"
      rel="noopener noreferrer"
      style={{ cursor: 'pointer' }}
    >
      {props.children}
    </a>
  );
};

export default LinkBlank;