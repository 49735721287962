import React from 'react';

const PurchaseSuccessPage = () => {
  return (
    <div>
      <h2 className="h1" style={{ textAlign: 'center', color: '#4CAF50', marginTop: '100px' }}>
        Your purchase was successfull! 
      </h2>
    </div>
  );
};

export default PurchaseSuccessPage;