import React, { Fragment } from 'react';
import LinkBlank from '../LinkBlank/LinkBlank';

const CleverLink: React.FC<{ className?: string, href?: string }> = (props) => {
  return props.href ? (
    <LinkBlank
      className={props.className}
      href={props.href}
    >
      {props.children}
    </LinkBlank>
  ) : (
    <Fragment>
      {props.children}
    </Fragment>
  );
};

export default CleverLink;