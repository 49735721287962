import React, { Fragment } from 'react';
import LinkBlank from '../LinkBlank/LinkBlank';

import contactBanner from '../../assets/img/top-banners/contact.png';
import phone from '../../assets/img/phone.png';
import email from '../../assets/img/email.png';
import fb from '../../assets/img/icons/facebook.svg';
import instagram from '../../assets/img/icons/instagram.svg';
import background from '../../assets/img/contact-bg.png';

import './ContactPage.css';

const ContactPage = () => {
  return (
    <Fragment>
      <div className="top-banner">
        <img src={contactBanner} alt="contact" width="1172" height="180" />
      </div>
      <div className="container contact-page">
        <div className="flex flex-space-around">
          <div>
            <h2 className="h2 uppercase">Huihan Liu</h2>
            <address className="h3">
              <div className="icon-container">
                <span className="round-icon lg">
                  <img src={phone} alt="" />
                </span>
                <span>Tel: (925) 270 -9825</span>
              </div>
              <div className="icon-container">
                <span className="round-icon lg">
                  <img src={email} alt="" />
                </span>
                <a
                  className="interactive-link"
                  href="mailto:huihanliu@yahoo.com"
                >
                  huihanliu@yahoo.com
                </a>
              </div>
              <div className="icon-container">
                <LinkBlank
                  className="round-icon lg"
                  href="https://www.facebook.com/huihan.liu.1"
                >
                  <img src={fb} alt="" />
                </LinkBlank>
                <LinkBlank 
                  className="interactive-link"
                  href="https://www.facebook.com/huihan.liu.1"
                >
                  fb.com/huihan.liu.1
                </LinkBlank>
              </div>
              <div className="icon-container">
                <LinkBlank
                  className="round-icon lg"
                  href="https://www.instagram.com/huihanliuartstudio/"
                >
                  <img src={instagram} alt="" />
                </LinkBlank>
                <LinkBlank 
                  className="interactive-link"
                  href="https://www.instagram.com/huihanliuartstudio/"
                >
                  @huihanliuartstudio
                </LinkBlank>
              </div>
            </address>
          </div>
          <div>
            <h2 className="h2 uppercase">Weizhen Liang</h2>
            <address className="h3">
              <div className="icon-container">
                <LinkBlank
                  className="round-icon lg"
                  href="https://www.facebook.com/weizhen.laing"
                >
                  <img src={fb} alt="" />
                </LinkBlank>
                <LinkBlank
                  className="interactive-link"
                  href="https://www.facebook.com/weizhen.laing"
                >
                  fb.com/weizhen.laing
                </LinkBlank>
              </div>
            </address>
          </div>
        </div>
        <div className="image-container">
          <img src={background} alt="" />
        </div>
      </div>
    </Fragment>
  );
};

export default ContactPage;