import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import GalleryHeader from '../GalleryHeader/GalleryHeader';
import CleverLink from '../CleverLink/CleverLink';
import ArtworkSliderItem from '../ArtworkSliderItem/ArtworkSliderItem';
import { Artist, getArtists } from '../../data/artistAndState';
import { getImages } from '../../data/showEventData';
import { createSliderSettings } from '../../misc/helpers';
import eventBanner from '../../assets/img/top-banners/event.png';

const ShowEventPage = () => {
  const params = useParams<{ artist: Artist, item: string }>();

  const { currentArtist, otherArtist } = getArtists(params.artist);
  const images = getImages(params.artist);

  function tweakHistory(index: number) {
    window.history.replaceState(null, '', `${process.env.PUBLIC_URL}/event/${currentArtist.id}/${images[index].key}`);
  }

  let index = 0;
  if (params.item) {
    index = images.findIndex(i => i.key === params.item);
  } else if (images.length) {
    tweakHistory(0);
  }

  return (
    <Fragment>
      <div className="top-banner">
        <img src={eventBanner} alt="event" width="1172" height="180" />
      </div>
      <div className="container">
        <GalleryHeader
          title={currentArtist.name}
          linkSiblingCaption={otherArtist.name}
          linkSiblingUrl={`/event/${otherArtist.id}`}
        >
        </GalleryHeader>
        <div className="slider-artwork" key={currentArtist.id}>
          <TinySlider
            settings={createSliderSettings(index)}
            onTransitionStart={(info: any) => tweakHistory(info.displayIndex - 1)}
          >
            {
              images.map(image => (
                <div key={image.key}>
                  <ArtworkSliderItem
                    image={image}
                    srcMd={() => require(`../../assets/img/showEvent/${currentArtist.id}/${image.filename}`)}
                    srcLg={() => require(`../../assets/img/showEvent/${currentArtist.id}/${image.filename}`)}
                  />
                  <div className="styled-block mt-20">
                    <CleverLink href={image.link}>
                      <span className="flex flex-direction-column">
                        {
                          image.content.map((c, i) => (
                            <Fragment key={i}>
                              <span className="mb-10">
                                {c}
                              </span>
                            </Fragment>
                          ))
                        }
                      </span>
                    </CleverLink>
                  </div>
                </div>
              ))
            }
          </TinySlider>
        </div>
      </div>
    </Fragment>
  );
};

export default ShowEventPage;