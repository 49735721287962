import React from 'react';
import { NavLink } from 'react-router-dom';

import './Navigation.css';

const Navigation: React.FC<{ show: boolean }> = ({ show }) => {
  const navCss = 'site-nav' + (show ? ' show' : '');

  return (
    <nav role="navigation" className={navCss}>
      <div className="container">
        <ul>
          <li>
            <NavLink 
              to="/about"
              className="interactive-link"
            >
              About us
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/artwork"
              className="interactive-link"
            >
              Art work
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/gallery"
              className="interactive-link"
            >
              Galleries
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/event/huihan"
              className="interactive-link"
              isActive={(_, location) => location.pathname.startsWith('/event')}
            >
              Show Event
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/workshop/huihan"
              className="interactive-link"
              isActive={(_, location) => location.pathname.startsWith('/workshop')}
            >
              Workshop
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/bookdvd"
              className="interactive-link"
            >
              Book / DVD
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/contact"
              className="interactive-link"
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;