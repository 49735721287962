import React, { Fragment } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import GalleryHeader from '../GalleryHeader/GalleryHeader';
import LinkBlank from '../LinkBlank/LinkBlank';
import ArtworkSliderItem from '../ArtworkSliderItem/ArtworkSliderItem';
import { Artist, getArtists, getStates, State } from '../../data/artistAndState';
import { getImages } from '../../data/artworkData';
import artworkBanner from '../../assets/img/top-banners/artwork.png';
import { createSliderSettings, crunchify, imgName } from '../../misc/helpers';
import './ArtworkGalleryDetailPage.css';

const ArtworkGalleryDetailPage = () => {
  const params = useParams<{ artist: Artist, state: State }>();
  const location = useLocation();

  const { currentArtist, otherArtist } = getArtists(params.artist);
  const { currentState, otherState } = getStates(params.state);
  const images = getImages(params.artist, params.state);
  let search = location.search;
  if (search && search[search.length - 1] === '=') {
    //pinterest appends a '=' to urls in some cases. we're trimming it here.
    search = search.substr(0, search.length - 1);
  }
  const index = search ? images.findIndex(i => '?art_' + imgName(i.src) === search) : 0;

  return (
    <Fragment>
      <div className="top-banner">
        <img src={artworkBanner} alt="artwork" width="1172" height="180" />
      </div>

      <div className="container">
        <GalleryHeader
          title={currentArtist.name}
          linkSiblingCaption={otherArtist.name}
          linkSiblingUrl={`/artwork/${otherArtist.id}/${currentState.id}`}
        >
          <span className="h2 interactive-link active">
            {currentState.caption}
          </span>
          <Link className="h2 interactive-link" to={`/artwork/${currentArtist.id}/${otherState.id}`}>
            {otherState.caption}
          </Link>
        </GalleryHeader>

        <div className="slider-artwork">
          <TinySlider
            settings={createSliderSettings(index)}
          >
            {
              images.map(image => (
                <div key={image.src}>
                  <ArtworkSliderItem
                    image={image}
                    srcMd={() => require(`../../assets/img/artwork/${currentArtist.id}/md/${image.src}`)}
                    srcLg={() => require(`../../assets/img/artwork/${currentArtist.id}/lg/${image.src}`)}
                  />
                  <p>
                    <span>
                      <strong>Size</strong>: {image.size}, {image.material}
                    </span>
                    <span className="shareline">
                      <strong className="uppercase">Share</strong>:
                      <LinkBlank
                        className="round-icon sm facebook"
                        href={'http://www.facebook.com/sharer.php?u=' + crunchify(window.location.href)}
                      >
                        <img src={require('../../assets/img/icons/facebook.svg')} alt="facebook" />
                      </LinkBlank>
                      <LinkBlank
                        className="round-icon sm pinterest"
                        href={'https://pinterest.com/pin/create/button/?url=' + crunchify(window.location.href) + '&media=' + crunchify(window.location.origin + '/' + require(`../../assets/img/artwork/${currentArtist.id}/lg/${image.src}`)) + '&description=' + crunchify(image.title)}
                      >
                        <img src={require('../../assets/img/icons/pinterest.svg')} alt="pinterest" />
                      </LinkBlank>
                    </span>
                  </p>
                </div>
              ))
            }
          </TinySlider>
        </div>
      </div>
    </Fragment>
  );
};

export default ArtworkGalleryDetailPage;