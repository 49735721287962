import React, { Fragment } from 'react';
import LinkBlank from '../LinkBlank/LinkBlank';

import aboutBanner from '../../assets/img/top-banners/about.png';
import fbIcon from '../../assets/img/icons/facebook.svg';
import instagramIcon from '../../assets/img/icons/instagram.svg';
import huihan from '../../assets/img/huihan.jpg';
import huihanSign from '../../assets/img/huihan-sign.png';
import weizhen from '../../assets/img/weizhen.jpg';
import weizhenSign from '../../assets/img/weizhen-sign.png';

import './AboutUsPage.css';

const AboutUsPage = () => {
  return (
    <Fragment>
      <div className="top-banner">
        <img src={aboutBanner} alt="about" width="1172" height="180" />
      </div>
      <div className="container">
        <ul className="about-content">
          <li>
            <h2 className="h1 icon-container">
              <span>
                Huihan Liu
              </span>
              <LinkBlank
                className="round-icon facebook"
                href="https://www.facebook.com/huihan.liu.1"
              >
                <img src={fbIcon} alt="facebook" width="30" height="30" />                
              </LinkBlank>
              <LinkBlank
                className="round-icon instagram"
                href="https://www.instagram.com/huihanliuartstudio"                
              >
                <img src={instagramIcon} alt="instagram" width="28" height="28" />
              </LinkBlank>
            </h2>
            <div className="sidebar-left align-center">
              <div>
                <img src={huihan} alt="Huihan Liu" width="255" height="340" />
              </div>
              <div>
                <img src={huihanSign} alt="Huihan Liu sign" width="250" height="93" />
              </div>
            </div>
            <div className="styled-block">
              <p>
                A master signature member of the Oil Painters of America, a master signature member of American
                Impressionist Society and an artist signature member of California art Club, Huihan Liu was trained in the
                Guangzhou Academy of Fine Art in China in 1972 with a BA and MFA from the Academy of Art College of San
                Francisco in 1989. With more than twenty years of his professional career as an illustrator, teacher, and
                painter, he won Best of Show Award in the Oil Painters of American Regional Exhibition in 1996. Huihan Liu
                was featured in Art of the West magazine in 1998, 2003, and 2005; in The Artist&rsquo; Magazine in April
                1999; and in Southwest Art in April 2000.
              </p>

              <h3 className="h3 uppercase">
                Education
              </h3>
              <ul>
                <li>Guangzhou Academy of Fine Art, China, BA (1972-1975)</li>
                <li>Graduate School of Guangzhou Academy of Fine Art, China (1985-1987)</li>
                <li>Academy of Art College, San Francisco, MFA (1987-1989)</li>
              </ul>

              <h3 className="h3 uppercase">
                Exhibition
              </h3>
              <ul>
                <li>Exhibition of Guangdong Fine Art, Guangzhou Museum of Fine Art, China 2009</li>
                <li>
                  Plein Air Art Exhibition, Bowers Museum of Cultural Art 7th Annual Sight of Southern California, Santa
                  Ana, California, 2003
                </li>
                <li>Western Rendezvous of Art Exhibition, Helena, Montana, 2003-2018</li>
                <li>Oil Painters of America National Juried Exhibition, 1995-2016</li>
                <li>
                  California Art Club Annual Gold Medal Juried Exhibition, 1998, 2000, 2002, 2003, 2004, 2006, 2009, 2012,
                  2014, 2015, 2018 
                </li>
                <li>Oil Painters of America The East / Southeast Regional Exhibition, 2002</li>
                <li>Artist For The New Century Invitational Exhibition, Bennington Center for the Arts, Bennington, Vermont, 2002</li>
                <li>Top 200 Arts for the Parks Competition, The National Academy of the Arts, Jackson, WY, 2001</li>
                <li>Exhibition of Guangdong Contemporary Oil Paintings, Guangzhou, China, 2001</li>
              </ul>

              <h3 className="h3 uppercase">
                Show
              </h3>
              <ul>
                <li>"Two Person Show-Huihan Liu and Weizhen Liang”, The Sylvan Gallery, Charleston, SC. 2013</li>
                <li>"Two Person Show-Huihan liu and weizhen Liang”, Total Arts Gallery, Taos, NM. 2011, 2018</li>
                <li>"Two Person Show – Huihan Liu & Weizhen Liang,” The Sylvan Gallery, Charleston, SC 2007</li>
                <li>"Four Person Show,” The Sylvan Gallery, Charleston, SC, 2005</li>
                <li>"Two Person Show,” Total Art Gallery, Taos, NM, 2002</li>
                <li>"Dedication, An Exhibit of Art of Huihan Liu,” Solo Show, The Ottinger Gallery, Chicago, IL, 2001</li>
                <li>Solo Show, Robert & Li Art Gallery, Taiwan, (1998, 2000, 2002)</li>
              </ul>
              
              <h3 className="h3 uppercase">
                Awards
              </h3>
              <ul>
                <li>"Best of Show Award", Western Rendevzous of Art Exhibition, The Brinton Museum, Big Horn, WY, 2016, 2017.</li>
                <li>Founders' Award, Western Rendezvous of Art Exhibition, The Brinton Museum, Big Horn, WY, 2018.</li>
                <li>
                  Founders' Award for Master Signature Members. Members' Choice Award for Master Signature Members. Oil
                  painters of American National Juried Exhibition, 2017. 
                </li>
                <li>Award of Merit, Western Rendezvous of Art Exhibition, Helena, Montana, 2012</li>
                <li>International Artist Award – Finalist for a work entitled "Making Wishes,” Issue 7C, December 2009</li>
                <li>Gold Medal for the Master Signature Division at the Oil Painters of America Western Regional Show, 2009</li>
                <li>
                  South West Art Award of Excellence, California Art Club Annual Gold Medal Exhibition, Pasadena Museum of
                  California Art, 2009
                </li>
                <li>"Ben Stahl Artist’s Choice,”Western Rendezvous Art Exhibition , Helena, Montana, 2005, 2006</li>
                <li>"Awards of Excellence,”Western Rendezvous Art Exhibition, Helena, Montana, 2003, 2004</li>
                <li>"Third Place Winner,” Plein Air Art Exhibition , Bowers Museum of Culture Art, Santa Ana, California, 2004</li>
                <li>"First Place Painting,” Plein Air Art Exhibition in Bowers Museum of Cultural Art, Santa Ana, California, 2003</li>
                <li>
                  "Best of Show in Painting – John Scott People’s Choice Award,” "The Awards of Merit,” The 25th Western
                  Rendezvous of Art, Helena, Montana in August 2003
                </li>
                <li>
                  "First Place Winner,” The California Art Club held its Ninth Annual Mission San Juan Capistrano Plein
                  Air painting event, August 2003
                </li>
                <li>
                  Art of the West Publisher’s Choice Award, Artist for the New Century Invitational Exhibition, Bennington
                  Center For The Arts, Bennington, Vermont 2002
                </li>
                <li>
                  "Gold Medal for Best Painting,” California Art Club’s 92nd Annual Juried Exhibition, Pasadena Historical
                  Museum Pasadena, CA, 2002
                </li>
                <li>
                  Master Signature Member’s Award of Excellence by Art of the West Magazine, Oil Painters of America
                  National Juried Exhibitionh, Chicago, IL 2002
                </li>
                <li>
                  Master Signature Member’s Award of Excellence, Oil Painters of America, The East / Southeast Regional
                  Exhibition, richmond, Virginia, 2002
                </li>
                <li>
                  Master Signature Member’s Award of Excellence, Oil Painters of America National Juried Exhibition, Palm
                  Springs, CA, 2001
                </li>
                <li>
                  The Winsor & Newton Painting Award, Oil Painters of America National Juried Exhibition, P. & C.
                  Galleries, Georgetown, Washington D.C., 1998
                </li>
                <li>
                  The Tara Fredricks Canvas Company Award, Oil Painters of America National Juried Exhibition, Greenhouse
                  Gallery of Fine Art, San Antonia, TX, 1996
                </li>
                <li>
                  The Grumbacher Gold Medallion Award, Oil Painters of America National Juried Exhibition, Greenhouse
                  Gallery of Fine Art, San Antonio, TX, 1996
                </li>
                <li>Best of Show Award, Oil Painters of America Pacific Coast Regional Juried Exhibition, Fremont, CA, 1996</li>
              </ul>

              <h3 className="h3 uppercase">
                Editorial
              </h3>
              <ul>
                <li>"Personal Connections", Art of the West Magazine, May/June 2017</li>
                <li>"From the East and of the West", Western Art & Architecture Magazine, August/September, 2017</li>
                <li>"Weizhen Liang & Huihan Liu Couple Show”, American Art Collector, April, 2013</li>
                <li>"Building Bridges”, by Norman Kolpas, Southwest Art Magazine, September, 2011</li>
                <li>” I Have a Fever”, by Barbara Coyner, art of the West Magerzine, July/ August, 2010</li>
                <li>
                  "American Dream : California painter and Chinese native Huihan Liu’s versatility is hard-earned,” by
                  Todd Wilkinson, Southwest Art Magazine, October, 2003
                </li>
                <li>"Art is Life, Not a Living,” by Barbara Coyner, Art of the West Magazine, September / October, 2003</li>
                <li>"Artist Worth Watching : Huihan Liu,” published by Art-Talk Magazine, January, 2001</li>
                <li>
                  "Huihan Liu,” by Chuan Huang, Professor, and Shirl Smithson, President of Oil Painters of America;
                  published by Robert & Li Gallery, Taiwan. 1998
                </li>
                <li>"A Joyful Feeling,” by Barbara Coyner, Art of the West Magazine, September / October, 1998</li>
                <li>"Capturing Character – Special Section: Portraits,” by Huihan Liu, The Artist’s Magazine, April, 1998</li>
                <li>
                  "Winners : The Judges of Oil Painters of America Focus on Time-Honored Standards,” by Susan Hallsten
                  McGarry, Southwest art Magazine, May, 1997
                </li>
                <li>"The Chinese Connection,” by Shirl Smithson, Art of The West Magazine, January / February, 1997</li>
              </ul>

              <h3 className="h3 uppercase">
                Museum Collection
              </h3>
              <ul>
                <li>"Summer Night," Oil 30"x15", The Brinton Museum purchase Award, 2017</li>
                <li>
                  "Eaton Canyon,” Oil 24″x30″, First Place Winner, Bowers Museum Contemporary Plein Air Permanent
                  collection, Santa Ana, California, 2003,
                </li>
                <li>
                  "Silence of the Mission,” Oil 24″x18″, First Place Winner in Ninth Annual Mission San Juan Capistrano
                  Plein Air painting event, Wilmington Trust purchase collection, 2003
                </li>
              </ul>

              <h3 className="h3 uppercase">
                Teaching Experience
              </h3>
              <ul>
                <li>Instructor – Drawing and painting, Art School of Guangzhou Academy of Fine Art, Guangzhou, China, 1979-1985</li>
                <li>
                  Part-time Instructor – Instructor /Advisor at undergrad and graduate School of Academy of Art
                  University, San Francisco, 1993-2011
                </li>
                <li>Informative Instructor – Painting, Fechin Art Workshops, San Cristobal, NM, 1998-Now</li>
              </ul>

              <h3 className="h3 uppercase">
                Membership
              </h3>
              <ul>
                <li>Master Member of American Impresionist Society</li>
                <li>Master Signature Member of Oil Painters of America</li>
                <li>Signature Member of California Art Club</li>
              </ul>
            </div>
          </li>
          <li>
            <h2 className="h1 align-right icon-container">
              <span>
                Weizhen Liang
              </span>
              <LinkBlank
                className="round-icon facebook"
                href="https://www.facebook.com/weizhen.laing"
              >
                <img src={fbIcon} alt="facebook" width="30" height="30" />
              </LinkBlank>
            </h2>
            <div className="sidebar-right align-center">
              <div>
                <img src={weizhen} alt="Weizhen Liu" width="255" height="340" />
              </div>
              <div>
                <img src={weizhenSign} alt="Weizhen Liu sign" width="86" height="114" />
              </div>
            </div>
            <div className="styled-block">
              <p>
                Weizhen Liang was born in January 1, 1955. She is a master member of American Impressionist Society and an
                artist member of the California Art Club. She earned a BA in the major of Chinese Literature from Ji Nan
                university of Guang Zhou in China, and work as a playwright in Guang Dong TV station before she move to the
                United States. She showed early interest in art and was influenced by her aunt, Zhong An-Zhi, a famous
                painter and professor in the Guangzhou Academy of Fine Arts. In 1998, Weizhen began studying painting
                seriously and finished advanced program in Guangzhou Academy of Fine Arts in China in 2009.
                They have lived over decades in the East Bay of San Francisco.
              </p>

              <h3 className="h3 uppercase">
                Education
              </h3>
              <ul>
                <li>Guangzhou Academy of Fine Arts, China, (2008-2009)</li>
                <li>Ji Nan University, China, BA (1980-1984)</li>
              </ul>

              <h3 className="h3 uppercase">
                Exhibition
              </h3>
              <ul>
                <li>Oil Painters of America 25th National Juried Exhibition, 2016</li>
                <li>Greenwich Workshop exhibition (2010, 2007)</li>
                <li>Salon International at Greenhouse Gallery (2010, 2007, 2005)</li>
                <li>The Northwest Rendezvous, Montana (2004, 2003)</li>
                <li>National Oil Painters of America (national, regional), (2003, 2002, 2001)</li>
              </ul>

              <h3 className="h3 uppercase">
                Show
              </h3>
              <ul>
                <li>"Two Person Show - HuiHan Liu and WeiZhen Liang", Total Arts Gallery, Taos, NM 2018</li>
                <li>"Two Person Show - Huihan Liu and Weizhen Liang”, The Sylvan Gallery, Charleston, SC. 2013</li>
                <li>"Two Person Show - Huihan Liu and Weizhen Liang”, Total Arts Gallery, Taos, NM. 2011</li>
                <li>"Two Person Show” Antioch Lynn House Gallery, California, 2008</li>
                <li>"Four Person Show” Antioch Lynn House Gallery, California, 2006</li>
                <li>"Tow Person Show”, The Sylvan Gallery, Charleston, SC. 2007</li>
                <li>"Four Person Show”, The Sylvan Gallery, Charleston, BC. 2006</li>
              </ul>

              <h3 className="h3 uppercase">
                Award
              </h3>
              <ul>
                <li>"Still Life Award of Excellence", Oil Painters of America National Jured Exhibition, 2016</li>
                <li>"Top 40” Award, Greenwich Workshop, 2007</li>
                <li>”Top 50” Award, Greenhouse Gallery, 2005</li>
              </ul>

              <h3 className="h3 uppercase">
                Workshop
              </h3>
              <ul>
                <li>Civic Art Center, Walnut Creek, CA. (2011, 2010)</li>
              </ul>

              <h3 className="h3 uppercase">
                Gallery
              </h3>
              <ul>
                <li>The Sylvan Gallery, Charleston, South Carolina</li>
                <li>Mountain Song Galleries, Carmel by The Sea, California</li>
                <li>Germanton Gallery, Germanton, North Carolina</li>
                <li>Total Arts Gallery, Taos , New Mexico</li>
              </ul>

              <h3 className="h3 uppercase">
                Editorial
              </h3>
              <ul>
                <li>"Liu & Liang" - Total Arts Gallery, Southwest Art Magazine, August, 2018</li>
                <li>"Weizhen Liang & Huihan Liu Couple Show”, American Art Collector, April, 2013</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default AboutUsPage;

