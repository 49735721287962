import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import GalleryHeader from '../GalleryHeader/GalleryHeader';
import CleverLink from '../CleverLink/CleverLink';
import ArtworkSliderItem from '../ArtworkSliderItem/ArtworkSliderItem';
import { Artist, getArtists } from '../../data/artistAndState';
import { getImages } from '../../data/workshopData';
import { createSliderSettings } from '../../misc/helpers';
import workshopBanner from '../../assets/img/top-banners/workshop.png';
import './WorkshopPage.css';

const WorkshopPage = () => {
  const params = useParams<{ artist: Artist, item: string }>();

  const { currentArtist, otherArtist } = getArtists(params.artist);
  const images = getImages(params.artist);

  function tweakHistory(index: number) {
    window.history.replaceState(null, '', `${process.env.PUBLIC_URL}/workshop/${currentArtist.id}/${images[index].key}`);
  }

  let index = 0;
  if (params.item) {
    index = images.findIndex(i => i.key === params.item);
  } else if (images.length) {
    tweakHistory(0);
  }

  return (
    <Fragment>
      <div className="top-banner">
        <img src={workshopBanner} alt="workshop" width="1172" height="180" />
      </div>
      <div className="container">
        <GalleryHeader
          title={currentArtist.name}
          linkSiblingCaption={otherArtist.name}
          linkSiblingUrl={`/workshop/${otherArtist.id}`}
        >
        </GalleryHeader>
        <div className="slider-artwork" key={currentArtist.id}>
          {
            images.length ?
              (
                <TinySlider
                  settings={createSliderSettings(index)}
                  onTransitionStart={(info: any) => tweakHistory(info.displayIndex - 1)}
                >
                  {
                    images.map(image => (
                      <div key={image.key}>
                        <ArtworkSliderItem
                          image={image}
                          srcMd={() => require(`../../assets/img/workshop/${currentArtist.id}/md/${image.filename}`)}
                          srcLg={() => require(`../../assets/img/workshop/${currentArtist.id}/lg/${image.filename}`)}
                        />
                        <div className="styled-block mt-20">
                          <CleverLink href={image.link}>
                            <span className="flex flex-direction-column">
                              {
                                image.content.map((c, i) => (
                                  <Fragment key={i}>
                                    <span className="mb-10">
                                      {c}
                                    </span>
                                  </Fragment>
                                ))
                              }
                            </span>
                          </CleverLink>
                        </div>
                      </div>
                    ))
                  }
                </TinySlider>
              ) :
              (
                <h2>Coming soon...</h2>
              )
          }
        </div>
      </div>
    </Fragment>
  );
};

export default WorkshopPage;