import React from 'react';
import { Link } from 'react-router-dom';

import './GalleryHeader.css';

interface GalleryHeaderProps {
  title: string;
  linkSiblingCaption: string;
  linkSiblingUrl: string;
}

const GalleryHeader: React.FC<GalleryHeaderProps> = (props) => {
  return (
    <header className="gallery-header">
      <h2 className="h1">
        {props.title}
      </h2>

      {
        props.children &&
        <div className="links uppercase">
          {props.children}
        </div>
      }

      <div className="link-sibling">
        <Link className="h3 uppercase interactive-link" to={props.linkSiblingUrl}>
          <span className="square-icon">&gt;</span>
          {props.linkSiblingCaption}
        </Link>
      </div>
    </header>
  );
};

export default GalleryHeader;