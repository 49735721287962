import React, { Fragment } from 'react';
import Card from '../Card/Card';

import galleryBanner from '../../assets/img/top-banners/gallery.png';
import sageCreek from '../../assets/img/gallery/sage_creek.png';
import astoria from '../../assets/img/gallery/astoria.png';

const cardStyle: React.CSSProperties = { marginBottom: '20px' };

const GalleriesPage = () => {
  return (
    <Fragment>
      <div className="top-banner">
        <img src={galleryBanner} alt="gallery" width="1172" height="180" />
      </div>

      <div className="container">
        <div className="flex flex-space-around">
          <div>
            <h1 className="h1">Huihan Liu</h1>
            <div>
              <Card 
                name="Sage Creek Gallery" 
                img={sageCreek} 
                url="https://sagecreekgallery.com/#/huihan-liu"
                style={cardStyle}
                hideTitle={true} 
              />
              <Card
                name="Astoria Fine Art"
                img={astoria}
                url="https://www.astoriafineart.com/artist/313/Huihan-Liu"
                style={cardStyle}
                imgStyle={{ width: '225px' }}
                hideTitle={true} 
              />
            </div>
          </div>

          <div>
            <h1 className="h1">Weizhen Liang</h1>
            <div>
              <Card
                name="Sage Creek Gallery"
                img={sageCreek}
                url="https://sagecreekgallery.com/#/weizhen-liang"
                style={cardStyle}
                hideTitle={true} 
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GalleriesPage;