import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import artworkBanner from '../../assets/img/top-banners/artwork.png';
import anticipation from '../../assets/img/artwork/huihan/md/Anticipation.jpg';
import morningIncenseJohangMonastery from '../../assets/img/artwork/huihan/md/MorningIncenseJohangMonastery.jpg';
import dancingRoses from '../../assets/img/artwork/weizhen/md/DancingRoses.jpg';
import morningPeony from '../../assets/img/artwork/weizhen/md/MorningPeony.jpg';

import './ArtworkPage.css';

const ArtworkPage = () => {
  return (
    <Fragment>
      <div className="top-banner">
        <img src={artworkBanner} alt="artwork" width="1172" height="180" />
      </div>

      <div className="container">
        <div className="flex flex-wrap">
          <div className="flex-1">
            <h2 className="h1">
              <Link to="/artwork/huihan/archive">
                Huihan Liu
              </Link>
            </h2>

            <div className="flex">
              <div className="flex-1 artwork-thumbnail">
                <Link to="/artwork/huihan/archive">
                  <img src={anticipation} alt="" />
                </Link>
                <h3 className="h2 uppercase">
                  <Link to="/artwork/huihan/archive">
                    Archive Work
                  </Link>
                </h3>
              </div>

              <div className="flex-1 artwork-thumbnail">
                <Link to="/artwork/huihan/available">
                  <img src={morningIncenseJohangMonastery} alt="" />
                </Link>
                <h3 className="h2 uppercase">
                  <Link to="/artwork/huihan/available">
                    Available Work
                  </Link>
                </h3>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <h2 className="h1">
              <Link to="/artwork/weizhen/archive">
                Weizhen Liang
              </Link>
            </h2>

            <div className="flex">
              <div className="flex-1 artwork-thumbnail">
                <Link to="/artwork/weizhen/archive">
                  <img src={dancingRoses} alt="" />
                </Link>
                <h3 className="h2 uppercase">
                  <Link to="/artwork/weizhen/archive">
                    Archive Work
                  </Link>
                </h3>
              </div>

              <div className="flex-1 artwork-thumbnail">
                <Link to="/artwork/weizhen/available">
                  <img src={morningPeony} alt="" />
                </Link>
                <h3 className="h2 uppercase">
                  <Link to="/artwork/weizhen/available">
                    Available Work
                  </Link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ArtworkPage;