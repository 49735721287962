import React, { Fragment, useState } from 'react';
import Card from '../Card/Card';

import { loadStripe } from '@stripe/stripe-js';

import bookDvdBanner from '../../assets/img/top-banners/book.png';
import worksOfHuihanPng from '../../assets/img/book/works_of_huihan.png';
import oilPaintingPng from '../../assets/img/book/oil_painting_2.png';
import overseasPng from '../../assets/img/book/overseas.png';
import expressivePng from '../../assets/img/dvd/expressive_figure_painting.png';
import inTheStudioPng from '../../assets/img/dvd/in_the_studio.png';
import loadingGif from '../../assets/img/loading.gif';

import './BookDvdPage.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

const cardStyle: React.CSSProperties = { width: '255px', marginRight: '30px' };

const BookDvdPage = () => {
  const [loading, setLoading] = useState(false);

  const handleClick = async (id: string) => {
    setLoading(true);
    const stripe = await stripePromise;
    await stripe?.redirectToCheckout({
      lineItems: [{
        price: id,
        quantity: 1,
      }],
      mode: 'payment',
      successUrl: window.location.origin + '/purchase/success',
      cancelUrl: window.location.href,
      shippingAddressCollection: {
        allowedCountries: ['US', 'CA']
      }
    });
    setLoading(false);
  };

  return (
    <Fragment>
      <div className="top-banner">
        <img src={bookDvdBanner} alt="bookdvd" width="1172" height="180" />
      </div>

      <div className="container">
        <h2 className="h1">Books</h2>
        <div className="flex">
          <Card 
            name="The Works of Huihan Liu"
            img={worksOfHuihanPng}
            onClick={() => handleClick(process.env.REACT_APP_WORKS_ID as string)}
            style={cardStyle}
          />
          <Card 
            name="The Oil Painting of Huihan Liu 2"
            img={oilPaintingPng}
            onClick={() => handleClick(process.env.REACT_APP_OIL_PAINTING_ID as string)}
            style={cardStyle}
          />
          <Card 
            name="The Overseas Chinese Oil Painter: Huihan Liu And Weizhen Liang"
            img={overseasPng}
            onClick={() => handleClick(process.env.REACT_APP_OVERSEAS_ID as string)}
            style={cardStyle}
          />
        </div>

        <h2 className="h1">DVDS</h2>
        <div className="flex">
          <Card 
            name="Huihan Liu: Expressive Figure Painting"
            img={expressivePng}
            url="https://lilipubsorders.com/products/huihan-liu-expressive-figure-painting"
            style={cardStyle}
          />
          <Card 
            name="Huihan Liu: Twilight in Tibet"
            img={inTheStudioPng}
            url="https://lilipubsorders.com/products/huihan-liu-twilight-in-tibet"
            style={cardStyle}
          />
        </div>
      </div>
      <div 
        className="overlay" 
        style={{ display: loading ? 'block' : 'none' }}
      >
        <img 
          src={loadingGif} 
          className="loader" 
          alt="loading"
        />
      </div>
    </Fragment>
  );
};

export default BookDvdPage;
