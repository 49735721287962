import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './Footer.css';

const Footer = (props: RouteComponentProps) => {
  const classes = props.location.pathname === '/' ?
    'site-footer site-footer--inverted' :
    'site-footer has-divider'

  return (
    <div className="container">
      <footer className={classes} role="contentinfo">
        <div className="copyright">
          <div className="container">
            <span>Copyright &copy;</span>
            <span itemProp="copyrightYear"></span>
            <span
              itemProp="copyrightHolder provider sourceOrganization"
              itemScope
              itemType="http://schema.org/Organization"
              itemID="http://www.site.com">
              <span itemProp="name">
                Huihan &amp; Weizhen Art Studio
              </span>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default withRouter(Footer);