import { Artist, State } from "./artistAndState";

type ArtworkData = {
  [key in Artist]: {
    [key in State]: {
      [key: string]: {
        size: string;
        title: string;
        date: string;
        sold: boolean;
        material: string;
        isCover?: boolean;
        summary: string[];
      }
    }
  }
}

const artworkData: ArtworkData = {
  "huihan": {
    "available": {
      "PilgrimsMakingWishesLhasa.jpg": {
        "size": "30' x 24'",
        "title": "Pilgrims Making Wishes, Lhasa",
        "date": "2016",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "RongbukGlacierFootHillOfMtEverest.jpg": {
        "size": "12' x 12'",
        "title": "Rongbuk Glacier-Foot Hill Of Mt.Everest",
        "date": "2016",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "ShoppingDay.jpg": {
        "size": "30' x 40'",
        "title": "Shopping Day In China Town, SF",
        "date": "2015",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      }

    },

    "archive": {
      "MorningIncenseJohangMonastery.jpg": {
        "size": "24' x 36'",
        "title": "Morning Incense at Johang Monastery, Lhasa",
        "date": "2016",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "LookingBack.jpg": {
        "size": "24' x 18'",
        "title": "Looking Back",
        "date": "2016",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "Anticipation.jpg": {
        "size": "36' x 30'",
        "title": "Anticipation",
        "date": "2003",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "MyBuddy.jpg": {
        "size": "12' x 9'",
        "title": "My Buddy",
        "date": "2003",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "VillagerInZaxizong.jpg": {
        "size": "36' x 48'",
        "title": "Villager In Zaxizong",
        "date": "2005",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "CattleComing.jpg": {
        "size": "30' x 40'",
        "title": "Cattle Coming",
        "date": "2002",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		 "Childhood.jpg": {
        "size": "30' x 40'",
        "title": "Childhood",
        "date": "2003",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		 "PrayerWheel.jpg": {
        "size": "14' x 18'",
        "title": "Prayer Wheel",
        "date": "2015",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"Anticipation_2.jpg": {
        "size": "30' x 15'",
        "title": "Anticipation",
        "date": "2015",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"PilgrimsGathering.jpg": {
        "size": "36' x 40'",
        "title": "Pilgrims Gathering",
        "date": "2016",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"Visiting.jpg": {
        "size": "40' x 30'",
        "title": "Visiting",
        "date": "2014",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"SpringEmergeFromVillage.jpg": {
        "size": "30' x 40'",
        "title": "Spring Emerge From Village",
        "date": "2012",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"Milk-TeaAtdusk.jpg": {
        "size": "36' x 48'",
        "title": "Milk-Tea at Dusk",
        "date": "2007",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"SunsetGather.jpg": {
        "size": "30' x 40'",
        "title": "Sunset Gather",
        "date": "2012",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"TanggulaPass.jpg": {
        "size": "30' x 15'",
        "title": "Tanggula Pass",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"SummerNight.jpg": {
        "size": "30' x 15'",
        "title": "Summer Night",
        "date": "2017",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"FaithfulFriends.jpg": {
        "size": "10' x 20'",
        "title": "Faithful Friends",
        "date": "2017",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },

		"TibetanGirl.jpg": {
        "size": "12' x 9'",
        "title": "Tibetan Girl",
        "date": "2017",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"MorningDance.jpg": {
        "size": "30' x 24'",
        "title": "Morning Dance",
        "date": "2018",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		// "TibetanGirl.jpg": {
    //     "size": "12' x 9'",
    //     "title": "Tibetan Girl",
    //     "date": "2017",
    //     "sold": true,
    //     "material": "oil on canvas",
    //     "summary": [
    //       ""
    //     ]
    //   },
		"Dream.jpg": {
        "size": "12' x 12'",
        "title": "Dream",
        "date": "2018",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"StreetOfGanze.jpg": {
        "size": "30' x 40'",
        "title": "Street Of Ganze",
        "date": "2004",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"Rest.jpg": {
        "size": "12' x 9'",
        "title": "Rest",
        "date": "2015",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		// "Rest.jpg": {
    //     "size": "12' x 9'",
    //     "title": "Rest",
    //     "date": "2015",
    //     "sold": true,
    //     "material": "oil on canvas",
    //     "summary": [
    //       ""
    //     ]
    //   },
		"Teppanyaki.jpg": {
        "size": "9' x 12'",
        "title": "Teppanyaki",
        "date": "2013",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
		"MorningStroll.jpg": {
        "size": "20' x 10'",
        "title": "Morning Stroll",
        "date": "2015",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },

		"Aspen.jpg": {
        "size": "11' x 14'",
        "title": "Aspen",
        "date": "2005",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },

		// "SunsetGather.jpg": {
    //     "size": "30' x 40'",
    //     "title": "UNTITLED",
    //     "date": "2012",
    //     "sold": true,
    //     "material": "oil on canvas",
    //     "summary": [
    //       ""
    //     ]
    //   },

		"Drawing.jpg": {
        "size": "14' x 11'",
        "title": "Drawing",
        "date": "2012",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      }

    }
  },

  "weizhen": {
    "available": {
      "ChineseCloisonne.jpg": {
        "size": "24' x 24'",
        "title": "Chinese Cloisonne",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "MorningPeony.jpg": {
        "size": "20' x 10'",
        "title": "Morning Peony",
        "date": "2015",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "ManofHimalayas.jpg": {
        "size": "9' x 12'",
        "title": "Man of Himalayas",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "TibetanPrayer.jpg": {
        "size": "9' x 12'",
        "title": "Tibetan Prayer",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },

      "QuietMoment.jpg": {
        "size": "9' x 12'",
        "title": "Quiet Moment",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },

      "RoseAroma.jpg": {
        "size": "9' x 12'",
        "title": "Rose Aroma",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },

      "DreamOfClimbingRoot.jpg": {
        "size": "40' x 20'",
        "title": "Dream Of Climbing Root",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },

      "DreamOfDream.jpg": {
        "size": "20' x 40'",
        "title": "Dream Of Dream",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },

      "GlassDream.jpg": {
        "size": "24' x 20'",
        "title": "Glass's Dream",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },

      "study1.jpg": {
        "size": "16' x 12'",
        "title": "Study 1",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },

      "study2.jpg": {
        "size": "16' x 12'",
        "title": "Study 2",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "study3.jpg": {
        "size": "40' x 24'",
        "title": "Study 3",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "FourSeasons.jpg": {
        "size": "4 pieces 24' x 32'",
        "title": "Four Seasons",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      // "ChineseCloisonne.jpg": {
      //   "size": "24' x 24'",
      //   "title": "Chinese Cloisonne",
      //   "date": "11/03/17",
      //   "sold": true,
      //   "material": "oil on canvas",
      //   "summary": [
      //     ""
      //   ]
      // },
      "SisterLinFourSeasons.jpg": {
        "size": "18' x 72'",
        "title": "Sister Lin 2012. Four Seasons",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      // "SisterLinFourSeasons.jpg": {
      //   "size": "Four Pieces, 18' x 72'",
      //   "title": "Sister Lin Four Seasons",
      //   "date": "11/03/17",
      //   "sold": true,
      //   "material": "oil on canvas",
      //   "summary": [
      //     ""
      //   ]
      // },
      "SisterLinSpring.jpg": {
        "size": "18' x 36'",
        "title": "Sister Lin Spring",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "SisterLinSummer.jpg": {
        "size": "18' x 36'",
        "title": "Sister Lin Summer",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "SisterLinAutumn.jpg": {
        "size": "18' x 36'",
        "title": "Sister Lin Autumn",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "SisterLinWinter.jpg": {
        "size": "18' x 36'",
        "title": "Sister Lin Winter",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "GulinareSeries1.jpg": {
        "size": "18' x 18'",
        "title": "Gulinare Series 1",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "GulinareSeries2.jpg": {
        "size": "18' x 18'",
        "title": "Gulinare Series 2",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "GulinareSeries3.jpg": {
        "size": "18' x 18'",
        "title": "Gulinare Series 3",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "GulinareSeries4.jpg": {
        "size": "18' x 18'",
        "title": "Gulinare Series 4",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      //TODO! This one should be found and uncommented!
      // "GulinareSeries5.jpg": {
      //   "size": "18' x 18'",
      //   "title": "Gulinare Series 5",
      //   "date": "11/03/17",
      //   "sold": true,
      //   "material": "oil on canvas",
      //   "summary": [
      //     ""
      //   ]
      // },

      "SolidityMobilitySeries2.jpg": {
        "size": "36' x 24'",
        "title": "Solidity Mobility Series 2",
        "date": "11/03/17",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      }
    },

    "archive": {
      "ColdWarm.jpg": {
        "size": "14' x 18'",
        "title": "Cold and Warm",
        "date": "2012",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "FacingSunOfHimalaya.jpg": {
        "size": "12' x 9'",
        "title": "Facing Sun Of Himalaya",
        "date": "2015",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "flower_au.jpg": {
        "size": "9' x 12'",
        "title": "Flower of Plateau",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "PinkScarf.jpg": {
        "size": "12' x 9'",
        "title": "Pink Scarf",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },

      "GreenCollar.jpg": {
        "size": "12' x 9'",
        "title": "Green Collar",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },

      "LockandKey.jpg": {
        "size": "14' x 18'",
        "title": "Lock and Key",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "GirlWithCurlyHair.jpg": {
        "size": "11' x 14'",
        "title": "Girl With Curly Hair",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },

      "WarmDream.jpg": {
        "size": "20' x 16'",
        "title": "Warm Dream",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "MorningLight.jpg": {
        "size": "9' x 12'",
        "title": "Morning Light",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },

      "GoingtoMountainTibet.jpg": {
        "size": "20' x 18'",
        "title": "Going to Mountain Tibet",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "LittleCow.jpg": {
        "size": "16' x 20'",
        "title": "LittleCow",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "AromaofRoses.jpg": {
        "size": "9' x 12'",
        "title": "Aroma of Roses",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "WarmShadow.jpg": {
        "size": "24' x 18'",
        "title": "Warm Shadow",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "SpringDream.jpg": {
        "size": "20' x 10'",
        "title": "Spring Dream",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "EnglishRosesInMorning.jpg": {
        "size": "14' x 11'",
        "title": "English Roses In Morning",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "RiverOfRoses.jpg": {
        "size": "30' x 20'",
        "title": "RiverOfRoses",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "RosesLoveSpring.jpg": {
        "size": "20' x 18'",
        "title": "Roses Love Spring",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "MobilityandSolidity.jpg": {
        "size": "36' x 18'",
        "title": "Mobility and Solidity",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "SummerMorning.jpg": {
        "size": "12' x 12'",
        "title": "Summer Morning",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "DancingRoses.jpg": {
        "size": "14' x 11'",
        "title": "Dancing Roses",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "isCover": true,
        "summary": [
          ""
        ]
      },
      "SunsetGold.jpg": {
        "size": "12' x 12'",
        "title": "Sunset Gold",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      },
      "CastDrawing.jpg": {
        "size": "24' x 18'",
        "title": "Cast Drawing",
        "date": "2008",
        "sold": true,
        "material": "oil on canvas",
        "summary": [
          ""
        ]
      }

    }
  }
};

export const getImages = (artist: Artist, state: State) => {
  const subset = artworkData[artist][state];
  const keys = Object.keys(subset);
  const images = keys.map(k => ({
    src: k,
    ...subset[k]
  }));
  return images;
}
