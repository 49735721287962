import { Artist } from "./artistAndState";

type ShowEventData = {
  [key in Artist]: {
    key: string;
    title: string;
    subtitle: string;
    filename: string;
    link: string;
    content: string[];
  }[]
}

const showEventData: ShowEventData = {
  huihan: [{
    key: 'prix-de-west',
    title: 'Prix de West Invitational Art Exhibition & Sale',
    subtitle: 'National Cowboy & Western Heritage Museum',
    filename: 'prix-de-west-2022.jpg',
    link: 'https://nationalcowboymuseum.org/prix-de-west/',
    content: [
      'Art Sale Event: June 17-18, 2022',
      'On Exhibit: June 2-August 7, 2022',
      'National Cowboy & Western Heritage Museum',
      '1700 Northeast 63rd St. Oklahoma City, OK 73111',
      '(405) 478-2250'
    ]
  }],
  weizhen: []
};

export const getImages = (artist: Artist) => showEventData[artist];
export const getKeys = (artist: Artist) => getImages(artist).map(i => i.key);
