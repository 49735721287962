import React, { Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import GalleryHeader from '../GalleryHeader/GalleryHeader';
import { Artist, getArtists, getStates, State } from '../../data/artistAndState';
import { getImages } from '../../data/artworkData';
import { imgName } from '../../misc/helpers';
import artworkBanner from '../../assets/img/top-banners/artwork.png';
import './ArtworkGalleryPage.css';

const ArtworkGalleryPage = () => {
  const params = useParams<{ artist: Artist, state: State }>();

  const { currentArtist, otherArtist } = getArtists(params.artist);
  const { currentState, otherState } = getStates(params.state);
  const images = getImages(params.artist, params.state);

  useEffect(() => {
    const galleryItems = document.querySelectorAll('.artwork-gallery-item');

    if (galleryItems && galleryItems.length > 0) {
      const observer = new IntersectionObserver(fadeout, { threshold: 0.1 });

      for (const item of Array.from(galleryItems)) {
        observer.observe(item);
      }

      return () => observer.disconnect();
    }

    function fadeout(entries: IntersectionObserverEntry[]) {
      for (const entry of entries) {
        const el = entry.target;
        if (entry.isIntersecting) {
          el.classList.remove('fade-below', 'fade-above');
        } else if (entry.boundingClientRect.top > 0) {
          el.classList.add('fade-below');
        } else {
          el.classList.add('fade-above');
        }
      }
    }
  });

  return (
    <Fragment>
      <div className="top-banner">
        <img src={artworkBanner} alt="artwork" width="1172" height="180" />
      </div>

      <div className="container">
        <GalleryHeader
          title={currentArtist.name}
          linkSiblingCaption={otherArtist.name}
          linkSiblingUrl={`/artwork/${otherArtist.id}/${currentState.id}`}
        >
          <span className="h2 interactive-link active">
            {currentState.caption}
          </span>
          <Link className="h2 interactive-link" to={`/artwork/${currentArtist.id}/${otherState.id}`}>
            {otherState.caption}
          </Link>
        </GalleryHeader>

        <div className="artwork-gallery">
          {
            images.map(image => {
              const href = `/artwork/${currentArtist.id}/${currentState.id}/detail?art_${imgName(image.src)}`;

              return (
                <div className="artwork-gallery-item artwork-thumbnail fade-below" key={image.src}>
                  <Link to={href}>
                    <img src={require(`../../assets/img/artwork/${currentArtist.id}/md/${image.src}`)} alt="" />
                  </Link>
                  <h3 className="h2 uppercase">
                    <Link to={href}>
                      {image.title}
                    </Link>
                  </h3>
                  <p><strong>Size</strong>: {image.size}, {image.material}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </Fragment>
  );
};

export default ArtworkGalleryPage;