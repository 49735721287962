import React from 'react';
import LinkBlank from '../LinkBlank/LinkBlank';

interface CardProps { 
  name: string;
  img: string;
  url?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
  hideTitle?: true;
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <div style={props.style}>
      <LinkBlank 
        href={props.url}
        onClick={props.onClick}
      >
        <img 
          src={props.img} 
          alt={props.name} 
          style={props.imgStyle}
        />
      </LinkBlank>
      {
        props.hideTitle ||
        <h3 className="h2 uppercase">
          <LinkBlank
            href={props.url}
            onClick={props.onClick}
          >
            {props.name}
          </LinkBlank>
        </h3>
      }
    </div>
  );
};

export default Card;