import React, { useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import NavigationToggle from '../NavigationToggle/NavigationToggle';
import Navigation from '../Navigation/Navigation';
import LogoAnimation from '../LogoAnimation/LogoAnimation';

import logo from '../../assets/img/logo.png';
import artStudio from '../../assets/img/art-studio.svg';
import huihanWeizhen from '../../assets/img/huihan-weizhen.svg';

import './Header.css';

const Header = (props: RouteComponentProps) => {
  const [show, setShow] = useState(false);

  const navigationToggleClick = () => {
    setShow(!show);
  };

  const isHomePage = props.location.pathname === '/';

  const headerStyle: React.CSSProperties = isHomePage ? {
    position: 'absolute',
    width: '100%',
    background: '#fff'
  } : { };

  return (
    <header className="site-header" style={headerStyle} role="banner">
      <div className="container logo-line">
        <div
          className="branding"
          itemProp="publisher"
          itemScope
          itemType="http://schema.org/Organization"
          itemID="CompanyName"
        >
          <Link to="/" itemProp="url" aria-label="Go to Homepage">
            <meta itemProp="legalName name" content="Huihan&WeizhenArtStudio" />
            <link itemProp="image" href={logo} />
            <span itemProp="logo" itemScope itemType="http://schema.org/ImageObject">
              <img src={logo} alt="Go to Homepage" itemProp="url" className="visually-hidden" />

              { isHomePage && <LogoAnimation /> }

              <img className="logo-sm" src={artStudio} alt="" width="149" height="17" />
              <img className="logo-lg" src={huihanWeizhen} alt="" width="524" height="37" />
            </span>
          </Link>
        </div>

        <h1 className="visually-hidden"> {getSeoHeader(props.location.pathname)} </h1>
        <NavigationToggle
          active={show}
          onClick={navigationToggleClick}
        />
      </div>

      <Navigation show={show} />
    </header>
  );
};

export default withRouter(Header);

function getSeoHeader(pathname: string) {
  if (pathname.startsWith('/about')) {
    return 'about';
  }

  if (pathname.startsWith('/artwork')) {
    return 'artwork';
  }

  if (pathname.startsWith('/gallery')) {
    return 'gallery';
  }

  if (pathname.startsWith('/event')) {
    return 'event';
  }

  if (pathname.startsWith('/workshop')) {
    return 'workshop';
  }

  if (pathname.startsWith('/bookdvd')) {
    return 'bookdvd';
  }

  if (pathname.startsWith('/contact')) {
    return 'contact';
  }

  return 'Huihan &amp; Weizhen Art Studio';
}