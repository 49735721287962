import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from './components/Header/Header';
import HomePage from './components/HomePage/HomePage';
import AboutUsPage from './components/AboutUsPage/AboutUsPage';
import BookDvdPage from './components/BookDvdPage/BookDvdPage';
import GalleriesPage from './components/GalleriesPage/GalleriesPage';
import ShowEventPage from './components/ShowEventPage/ShowEventPage';
import WorkshopPage from './components/WorkshopPage/WorkshopPage';
import ArtworkPage from './components/ArtworkPage/ArtworkPage';
import ContactPage from './components/ContactPage/ContactPage';
import ArtworkGalleryPage from './components/ArtworkGalleryPage/ArtworkGalleryPage';
import ArtworkGalleryDetailPage from './components/ArtworkGalleryDetailPage/ArtworkGalleryDetailPage';
import Footer from './components/Footer/Footer';
import PurchaseSuccessPage from './components/PurchaseSuccessPage/PurchaseSuccessPage';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import { getKeys as getWorkshopKeys } from './data/workshopData';
import { getKeys as getEventKeys } from './data/showEventData';

const workshopHuihanRouteKeys = getWorkshopKeys('huihan').join('|');
const workshopWeizhenRouteKeys = getWorkshopKeys('weizhen').join('|');

const eventHuihanRouteKeys = getEventKeys('huihan').join('|');
const eventWeizhenRouteKeys = getEventKeys('weizhen').join('|');

const App = () => {
  return (
    <Fragment>
      <Header />
      <div className="main-content">
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/artwork" exact>
            <ArtworkPage />
          </Route>
          <Route path="/artwork/:artist(huihan|weizhen)/:state(archive|available)" exact>
            <ArtworkGalleryPage />
          </Route>
          <Route path="/artwork/:artist(huihan|weizhen)/:state(archive|available)/detail">
            <ArtworkGalleryDetailPage />
          </Route>
          <Route path="/about">
            <AboutUsPage />
          </Route>
          <Route path="/gallery">
            <GalleriesPage />
          </Route>
          <Route path="/event/:artist(huihan)" exact>
            <ShowEventPage />
          </Route>
          <Route path={`/event/:artist(huihan)/:item(${eventHuihanRouteKeys})`} exact>
            <ShowEventPage />
          </Route>
          <Route path="/event/:artist(weizhen)" exact>
            <ShowEventPage />
          </Route>
          <Route path={`/event/:artist(weizhen)/:item(${eventWeizhenRouteKeys})`} exact>
            <ShowEventPage />
          </Route>
          <Route path="/workshop/:artist(huihan)" exact>
            <WorkshopPage />
          </Route>
          <Route path={`/workshop/:artist(huihan)/:item(${workshopHuihanRouteKeys})`} exact>
            <WorkshopPage />
          </Route>
          <Route path="/workshop/:artist(weizhen)" exact>
            <WorkshopPage />
          </Route>
          <Route path={`/workshop/:artist(huihan)/:item(${workshopWeizhenRouteKeys})`} exact>
            <WorkshopPage />
          </Route>
          <Route path="/bookdvd">
            <BookDvdPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
          <Route path="/purchase/success">
            <PurchaseSuccessPage />
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Fragment>
  );
}

export default App;
