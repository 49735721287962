import React, { Fragment } from 'react';
import TinySlider from 'tiny-slider-react';

import chineseCloisonneMd from '../../assets/img/artwork/weizhen/md/ChineseCloisonne.jpg';
import makingWishesMd from '../../assets/img/artwork/huihan/md/MakingWishes.jpg';
import prayerWheelMd from '../../assets/img/artwork/huihan/md/PrayerWheel.jpg';
import morningPeonyMd from '../../assets/img/artwork/weizhen/md/MorningPeony.jpg';

import chineseCloisonneLg from '../../assets/img/artwork/weizhen/lg/ChineseCloisonne.jpg';
import makingWishesLg from '../../assets/img/artwork/huihan/lg/MakingWishes.jpg';
import prayerWheelLg from '../../assets/img/artwork/huihan/lg/PrayerWheel.jpg';
import morningPeonyLg from '../../assets/img/artwork/weizhen/lg/MorningPeony.jpg';

import './HomePage.css';

const slides = [
  {
    srcMd: chineseCloisonneMd,
    srcLg: chineseCloisonneLg,
  },
  {
    srcMd: makingWishesMd,
    srcLg: makingWishesLg,
  },
  {
    srcMd: prayerWheelMd,
    srcLg: prayerWheelLg,
  },
  {
    srcMd: morningPeonyMd,
    srcLg: morningPeonyLg,
  },
];

const HomePage = () => {
  return (
    <Fragment>
      <div className="slider-home">
        <TinySlider settings={{
          mode: 'gallery',
          autoplay: true,
          autoplayButtonOutput: false,
          speed: 1000,
          autoplayTimeout: 7000,
          controls: false
        }}>
          {
            slides.map((s, i) => (
              <div key={i}>
                <a href="/">
                  <img src={s.srcMd}
                    srcSet={`${s.srcMd} 800w, ${s.srcLg} 1100w`}
                    sizes="150vw"
                    alt="a" />
                </a>
              </div>
            ))
          }
        </TinySlider>
      </div>
    </Fragment>
  );
};

export default HomePage;