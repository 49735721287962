import { TinySliderSettings } from "tiny-slider-react";

export const crunchify = (str: string) => str.replace(/\s/g, '%20').replace(/\//g, '%2F').replace(/:/g, '%3A');
export const imgName = (str: string) => str.replace(/\.jpg|\.png$/, '');

export const createSliderSettings = (index: number): TinySliderSettings => ({
  mode: 'gallery',
  nav: false,
  controlsText: ['<span class="square-icon lg">&lt;</span>', '<span class="square-icon lg">&gt;</span>'],
  autoHeight: true,
  startIndex: index
});