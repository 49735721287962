import { Artist } from "./artistAndState";

type WorkshopData = {
  [key in Artist]: {
    key: string;
    title: string;
    subtitle?: string;
    filename: string;
    link?: string;
    content: string[];
  }[]
}

const workshopData: WorkshopData = {
  huihan: [{
    key: 'franciscan-life-process-center',
    title: 'Franciscan Life Process Center',
    filename: 'franciscan-life-2022.jpg',
    link: 'https://lifeprocesscenter.org/events/huihan-liu-intensive-3-day-painting-workshop/',
    content: [
      'Franciscan Life Process Center',
      'August 25-27, 2022',
      '11650 Downes Street',
      'Lowell, MI 49331',
      '616-897-7842'
    ]
  }, {
    key: 'figure-painting-workshop',
    title: 'Figure Painting Workshop',
    filename: 'huihan-liu-card-1.jpg',
    link: 'http://www.carmelvisualarts.com/huihan-liu/',
    content: [
      'Carmel Visual Arts',
      'May 16-18, 2021',
      'The Barnyard Shopping Village, Carmel, CA'
    ]
  }, {
    key: 'huihan-liu-painting-the-figure',
    title: 'Huihan Liu – Painting the Figure',
    filename: 'fineart.jpg',
    link: 'https://fineartservices.info/product/huihan-liu-painting-the-figure',
    content: [
      'September 2-6, 2019'
    ]
  }, {
    key: 'figure-and-studio-painting',
    title: 'Figure and Studio Painting',
    subtitle: 'The Scottsdale Artists\' School',
    filename: 'scottsdale_figure_and_studio_painting.jpg',
    link: 'https://scottsdaleartschool.org/course/figure-and-studio-painting-5/',
    content: [
      'January 11-15, 2021',
      '(480) 990-1422'
    ]
  }, {
    key: 'home-studio',
    title: 'Home Studio Workshop',
    filename: 'home-studio.jpg',
    content: [
      'April 12-16, 2021',
      'Santa Rosa, CA'
    ]
  }, {
    key: 'fredericksburg-artists-school',
    title: 'Fredericksburg Artists School',
    filename: 'fredericksburg_artists_school.png',
    link: 'https://www.fbgartschool.com/2021_classes.htm',
    content: [
      'April 26-30, 2021'
    ]
  }],
  weizhen: []
};

export const getImages = (artist: Artist) => workshopData[artist];
export const getKeys = (artist: Artist) => getImages(artist).map(i => i.key);
