import React, { Fragment } from 'react';
import banner from '../../assets/img/top-banners/workshop.png';
import background from '../../assets/img/contact-bg.png';

import './NotFoundPage.css';

const NotFoundPage = () => {
  return (
    <Fragment>
      <div className="top-banner">
        <img src={banner} alt="banner" />
      </div>
      <div className="not-fount-page">
        <div className="text-404">404</div>
        <div className="text-400-description">Page could not be found</div>
        <div>
          <img src={background} alt="" />
        </div>
      </div>
    </Fragment>
  );
};

export default NotFoundPage;