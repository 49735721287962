import React, { Fragment } from 'react';
import CleverLink from '../CleverLink/CleverLink';

interface Image {
  title: string;
  subtitle?: string;
  link?: string;
}

const ArtworkSliderItem: React.FC<{
  image: Image,
  srcMd: () => any,
  srcLg: () => any
}> = ({ image, srcMd, srcLg }) => {
  return (
    <Fragment>
      <h3 className="h1-light uppercase js-header" style={{ marginTop: 0, width: 'calc(100% - 100px)' }}>
        <CleverLink
          className="interactive-link"
          href={image.link}
        >
          {image.title} <br />
          {image.subtitle}
        </CleverLink>
      </h3>
      <div>
        <CleverLink href={image.link}>
          <img
            src={srcMd()}
            srcSet={`${srcMd()} 800w, ${srcLg()} 1100w`}
            alt={image.title}
            sizes="(max-width: 70.56em) 150vw, 1100px"
          />
        </CleverLink>
      </div>
    </Fragment>
  );
}

export default ArtworkSliderItem;