export type Artist = 'huihan' | 'weizhen';
export type State = 'archive' | 'available';

const huihanInfo = {
  id: 'huihan',
  name: 'Huihan Liu',
};

const weizhenInfo = {
  id: 'weizhen',
  name: 'Weizhen Liang'
};

const archiveInfo = {
  id: 'archive',
  caption: 'Archive Work'
};

const availableInfo = {
  id: 'available',
  caption: 'Available Work'
};

export const getArtists = (artist: Artist) => artist === 'huihan'
  ? { currentArtist: huihanInfo, otherArtist: weizhenInfo }
  : { currentArtist: weizhenInfo, otherArtist: huihanInfo };

export const getStates = (state: State) => state === 'archive'
  ? { currentState: archiveInfo, otherState: availableInfo }
  : { currentState: availableInfo, otherState: archiveInfo };