import React from 'react';
import './NavigationToggle.css';

const NavigationToggle: React.FC<{ active: boolean, onClick: () => void }> = ({ active, onClick }) => {
  const css = 'nav-toggle' + (active ? ' active' : '');

  return (
    <button 
      className={css} 
      onClick={onClick}
      aria-label="Toggle sections"
    >
      <span></span>
    </button>
  );
};

export default NavigationToggle;